import _ from 'lodash'
import store from '@/store/store'

export default {
  data() {
    return {
      mixinInitialData: {},
      mixinInputData: {}
    }
  },

  computed: {
    mixinDiffFlg() {
      return !_.isEqual(this.mixinInitialData, this.mixinInputData)
    }
  },

  watch: {
    mixinDiffFlg: function(mixinDiffFlg) {
      mixinDiffFlg
        ? store.dispatch('petorelu/ngLeave')
        : store.dispatch('petorelu/okLeave')
    }
  },

  methods: {
    mixinSetInitialData() {
      this.mixinInitialData = _.cloneDeep(this.mixinInputData)
    }
  }
}
